import React from "react";
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import PropertyTypeSearch from "./sections/propertyTypeSearch";
import PropertyListing from "./sections/propertListing";
import PropertyTypes from "./sections/propertyType";
import Slider1 from "../components/sliders/slider1";
import AboutSection from "./sections/aboutSection";
import MainAgentSection from "./sections/mainAgentSection";
import AgentListing from "./sections/agentListing";
import ClientsSection from "./sections/clientsSection";
import ScrolButton from "../components/button/scrolButton";
import PropertyOwner from "./sections/propertyOwner";
import OwnerSection from "./sections/ownerSection";



export const HomePage = () => {
    

    return (
      <div className="container-xxl  p-0" style={{backgroundColor:"black"}}>
            <Header />
            <Slider1 />
            {/* <PropertyTypeSearch /> */}
            <PropertyTypes />
        <AboutSection   />
        <OwnerSection/>
        {/* <PropertyOwner/> */}
            <PropertyListing />
            <MainAgentSection />
            {/* <AgentListing /> */}
           {/* <ClientsSection /> */}
         
        <Footer />
        <ScrolButton/>
  </div>
    )
}