import { HomePage } from "../pages/homepage";
import { AboutPage } from "../pages/about";
import {ContactUs} from "../pages/contact"
export const RouteDate = [
    {
        path: "",
        element: <HomePage />,
        title:"HomePage"
    },
    {
        path: "/about",
        element: <AboutPage />,
        title:"AboutPage"
    },
    {
        path: "/contact",
        element: <ContactUs />,
        title:"ContactUs"
    },

]
