import React from "react"
import carousel from "../../assets/img/carousel-1.jpg";
import OwlCarousel from 'react-owl-carousel';

const Slider3 = ({options1}) => {
   

    return (
        <>
            
            <OwlCarousel className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s" {...options1}>
                  <div className="testimonial-item bg-light rounded p-3">
                      <div className="bg-white border rounded p-4">
                          <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
                  <div className="d-flex align-items-center">
                     
                              <img className="img-fluid flex-shrink-0 rounded" src={carousel}style={{ width: "45px", height: "45px" }} alt="asd" />
                              <div className="ps-3">
                                  <h6 className="fw-bold mb-1">Client Name</h6>
                                  <small>Profession</small>
                              </div>
                          </div>
                      </div>
              </div>
              <div className="testimonial-item bg-light rounded p-3">
                      <div className="bg-white border rounded p-4">
                          <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
                  <div className="d-flex align-items-center">
                     
                              <img className="img-fluid flex-shrink-0 rounded" src={carousel}style={{ width: "45px", height: "45px" }} alt="ads" />
                              <div className="ps-3">
                                  <h6 className="fw-bold mb-1">Client Name</h6>
                                  <small>Profession</small>
                              </div>
                          </div>
                      </div>
              </div>
              <div className="testimonial-item bg-light rounded p-3">
                      <div className="bg-white border rounded p-4">
                          <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
                  <div className="d-flex align-items-center">
                     
                              <img className="img-fluid flex-shrink-0 rounded" src={carousel}style={{ width: "45px", height: "45px" }} alt="asd" />
                              <div className="ps-3">
                                  <h6 className="fw-bold mb-1">Client Name</h6>
                                  <small>Profession</small>
                              </div>
                          </div>
                      </div>
              </div>
              <div className="testimonial-item bg-light rounded p-3">
                      <div className="bg-white border rounded p-4">
                          <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
                  <div className="d-flex align-items-center">
                     
                              <img className="img-fluid flex-shrink-0 rounded" src={carousel}style={{ width: "45px", height: "45px" }} />
                              <div className="ps-3">
                                  <h6 className="fw-bold mb-1">Client Name</h6>
                                  <small>Profession</small>
                              </div>
                          </div>
                      </div>
              </div>
              
                  <div className="testimonial-item bg-light rounded p-3">
                      <div className="bg-white border rounded p-4">
                          <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
                          <div className="d-flex align-items-center">
                              <img className="img-fluid flex-shrink-0 rounded" src={carousel} style={{ width: "45px", height: "45px" }} />
                              <div className="ps-3">
                                  <h6 className="fw-bold mb-1">Client Name</h6>
                                  <small>Profession</small>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="testimonial-item bg-light rounded p-3">
                      <div className="bg-white border rounded p-4">
                          <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
                          <div className="d-flex align-items-center">
                              <img className="img-fluid flex-shrink-0 rounded" src={carousel}style={{ width: "45px", height: "45px" }} />
                              <div className="ps-3">
                                  <h6 className="fw-bold mb-1">Client Name</h6>
                                  <small>Profession</small>
                              </div>
                          </div>
                      </div>
                  </div>
              </OwlCarousel>
       
        </>
    );

};

export default Slider3;
