import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';// Import Link from react-router-dom
import menu from "../../assets/img/menu-bar.png"
import logo from "../../assets/img/logo-new.svg"

const Header = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const location = useLocation();

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
      };

    
      const scrollToSection = (event) => {
        event.preventDefault();
    
        // Get the target section ID from href
        const targetId = event.currentTarget.getAttribute('href').substring(1);
        console.log("targetId", targetId);
    
        // Get the target section element
        const targetSection = document.getElementById(targetId);
        console.log("targetSection", targetSection);
    
        // Close the navbar by updating the state
        setIsNavbarOpen(false);
    
        // Scroll to the section with smooth behavior
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
    
    return (
        <>
        <div className="container-fluid nav-bar bg-transparent" style={{backgroundColor:"black"}}>
          <nav className="navbar navbar-collapse  navbar-light py-2 px-4" style={{backgroundColor:"black"}}>
              <Link to="/" className="navbar-brand d-flex align-items-center text-center"  >
              <div>
            <img src={logo} alt="logo" className='logo-style'  />
        </div>                     
              </Link>
              <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <img    onClick={toggleNavbar} className='menu-icon' src={menu} alt="Menu"></img>
              </button>
              <div className={`collapse text-center navbar-collapse ${isNavbarOpen ? 'show' : ''}`}   id="navbarCollapse">
                  <div className="navbar-nav ms-auto">
                      <Link to="/" className="nav-item nav-link active">Home</Link>
                      <Link className="nav-item nav-link" to="#about-us-new" onClick={scrollToSection}>About Us</Link>
                      <Link className="nav-item nav-link" to="#meet-the-ceo" onClick={scrollToSection}>Meet The CEO</Link>
                            
                            
                      {/* <div className="nav-item dropdown">
                          <Link to="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Property</Link>
                          <div className="dropdown-menu rounded-0 m-0">
                              <Link to="/" className="dropdown-item">Property List</Link>
                              <Link to="/" className="dropdown-item">Property Type</Link>
                              <Link to="/" className="dropdown-item">Property Agent</Link>
                          </div>
                      </div>
                      <div className="nav-item dropdown">
                          <Link to="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
                          <div className="dropdown-menu rounded-0 m-0">
                              <Link to="/" className="dropdown-item">Testimonial</Link>
                              <Link to="/" className="dropdown-item">404 Error</Link>
                          </div>
                      </div> */}
                      <Link to="/contact" className="nav-item nav-link">Contact Us</Link>
                  </div>
                  {/* <Link href="" className="btn btn-primary px-3 d-none d-lg-flex">Add Property</Link> */}
              </div>
          </nav>
      </div>
        
      </>
    );
};

export default Header;
