import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import about from "../../assets/img/img5.jfif"
const AboutSection = () => {

    function scrollToSection(event) {
        event.preventDefault();
        const targetSection = document.getElementById('target-section');
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
      
    return (
        <>
            
        <div className="container-xxl py-5" id="#about-us-new">
        <div className="container">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="about-img position-relative overflow-hidden p-5 pe-0">
                        <img className="img-fluid w-100" src={about}  alt="cons"/>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 className="mb-4 text-light">About Us</h1>
                    <p className="mb-4 text-light">At Value Rent Stay, we understand the unique needs of our discerning clientele. Our properties are meticulously curated to ensure the highest standards of quality and elegance. Whether you seek a serene residence or a vibrant urban lifestyle, our exceptional homes promise to elevate your living experience.
Join us at Value Rent Stay, and indulge in the finest that downtown living has to offer. Discover a place where every detail is designed to exceed your expectations, and where your satisfaction is our highest priority</p>
                    {/* <p className='text-light'><i className="fa fa-check text-primary me-3"></i>Tempor erat elitr rebum at clita</p>
                    <p className='text-light'><i className="fa fa-check text-primary me-3"></i>Aliqu diam amet diam et eos</p>
                    <p className='text-light'><i className="fa fa-check text-primary me-3"></i>Clita duo justo magna dolore erat amet</p>
                     */}
                     <Link onClick={scrollToSection} className="btn btn-primary py-3 px-5 mt-3">Know More</Link>
                </div>
                </div>
                
                
        </div>
            </div>
            </>
    );
};

export default AboutSection;
