import React from "react";
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import PropertyTypeSearch from "./sections/propertyTypeSearch";
import AboutSection from "./sections/aboutSection";
import MainAgentSection from "./sections/mainAgentSection";
import AgentListing from "./sections/agentListing";
import ClientsSection from "./sections/clientsSection";
import ScrolButton from "../components/button/scrolButton";
import GenericSection from "./sections/genericSectionPage";



export const AboutPage = () => {


    return (
      <div className="container-xxl  p-0" style={{backgroundColor:"black"}}>
            <Header />
            <GenericSection title="About Us"/>
            <PropertyTypeSearch />
            <AboutSection />
            <MainAgentSection />
            <AgentListing />
            <ClientsSection/>
        <Footer />
        <ScrolButton/>
  </div>
    )
}