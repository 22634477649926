import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import test from "../../assets/img/carousel-2.jpg";
const Footer = () => {
    return (
        <>
        
      <div className="container-fluid  text-white-50 footer pt-5 mt-5 wow fadeIn" style={{backgroundColor:"black",border:"1px solid gray"}} data-wow-delay="0.1s">
          <div className="container py-5">
              <div className="row g-5">
                  <div className="col-lg-6 col-md-6">
                      <h5 className="text-white mb-4">Get In Touch</h5>
                      {/* <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p> */}
                      <Link to="tel:+971555661839" ><p className="mb-2 text-white-50 "><i className="fa fa-phone-alt me-3"></i>+971 55 566 1839</p></Link>
                      <Link to="mailto:Info@valuerentstay.ae" ><p className="mb-2 text-white-50"><i className="fa fa-envelope me-3 text-white-50"></i>Info@valuerentstay.ae</p></Link>
                            <div className="d-flex pt-2">
                            <Link className="btn btn-outline-light btn-social" to="https://www.instagram.com/bnb_zee?igsh=MTR5am50eXI2cDFxNg%3D%3D&utm_source=qr"   target="_blank"  rel="noopener noreferrer"><i className="fab fa-instagram"></i></Link>
                            <Link className="btn btn-outline-light btn-social" to="https://www.tiktok.com/@bnb_z?_t=8p1r50KiTSh&_r=1" target="_blank"  rel="noopener noreferrer"><i className="fab fa-tiktok me-2"></i></Link>
                          
                      </div> 
                            
                  </div>
                  <div className="col-lg-6 col-md-6">
                      <h5 className="text-white mb-4">Quick Links</h5>
                      <Link className="btn btn-link text-white-50" to="/">Home</Link>
                        
                      <Link className="btn btn-link text-white-50" to="contact">Contact</Link>
                    
                  </div>
                  {/* <div className="col-lg-3 col-md-6">
                      <h5 className="text-white mb-4">Photo Gallery</h5>
                      <div className="row g-2 pt-2">
                          <div className="col-4">
                              <img className="img-fluid rounded bg-light p-1" src={test} alt="" />
                          </div>
                          <div className="col-4">
                              <img className="img-fluid rounded bg-light p-1" src={test} alt="" />
                          </div>
                          <div className="col-4">
                              <img className="img-fluid rounded bg-light p-1" src={test} alt="" />
                          </div>
                          <div className="col-4">
                              <img className="img-fluid rounded bg-light p-1" src={test} alt="" />
                          </div>
                          <div className="col-4">
                              <img className="img-fluid rounded bg-light p-1" src={test} alt="" />
                          </div>
                          <div className="col-4">
                              <img className="img-fluid rounded bg-light p-1" src={test} alt="" />
                          </div>
                      </div>
                  </div> */}
                  {/* <div className="col-lg-4 col-md-6">
                      <h5 className="text-white mb-4">Newsletter</h5>
                      <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                      <div className="position-relative mx-auto"  style={{ maxWidth: "400px" }}>
                          <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                          <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                      </div>
                  </div> */}
              </div>
          </div>
          {/* <div className="container">
              <div className="copyright">
                  <div className="row">
                      <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                          &copy; <Link className="border-bottom" href="#">Your Site Name</Link>, All Right Reserved. 
            
            Designed By <Link className="border-bottom" to="/">Azhar Akhtar</Link>
                      </div>
                      <div className="col-md-6 text-center text-md-end">
                          <div className="footer-menu">
                              <Link href="">Home</Link>
                              <Link href="">Cookies</Link>
                              <Link href="">Help</Link>
                              <Link href="">FQAs</Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
      </div> 
        </>
    );
};

export default Footer;
