import React from "react";
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import PropertyTypeSearch from "./sections/propertyTypeSearch";
import ContactSection from "./sections/contactSection";
import ScrolButton from "../components/button/scrolButton";
import GenericSection from "./sections/genericSectionPage";




export const ContactUs = () => {


    return (
      <div className="container-xxl  p-0"  style={{backgroundColor:"black"}}>
            <Header />
            <GenericSection title="Contact Us"/>
        {/* <PropertyTypeSearch /> */}
        <ContactSection/>
        <Footer />
        <ScrolButton/>
  </div>
    )
}