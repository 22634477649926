import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import calltoaction from "../../assets/img/call-to-action.jpg"
import pic from "../../assets/img/img6.jfif"
const MainAgentSection = () => {
    return (
        <div className="container-xxl py-5" >
        <div className="container">
            <div className=" rounded p-3">
                <div className="bg-dark rounded " style={{backgroundColor:"#000" }}>
                    <div className="row g-4 align-items-center" style={{backgroundColor:"#000" }}>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <img className="img-fluid rounded w-80 " src={pic} alt="" style={{height:"800px",width:"auto"}} />
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s"  >
                            <div className="mb-4">
                                <h1 className="mb-3 text-white">Contact With Our Certified Agent</h1>
                                <p className='text-white'>Dubai is a dazzling metropolis that stands as a testament to visionary ambition and cutting-edge innovation. Renowned for its awe-inspiring skyline dominated by the iconic Burj Khalifa, Dubai seamlessly blends traditional Arabian charm with futuristic marvels. Get in touch with us to find homes where luxury meets minimalism. </p>
                            </div>
                            <Link to="tel:+971555661839" className="btn btn-primary py-3 px-4 me-2"><i className="fa fa-phone-alt me-2"></i>Make A Call</Link>
                            <Link to="/contact" className="btn btn-dark py-3 px-4"><i className="fa fa-calendar-alt me-2"></i>Get Appoinment</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    );
};

export default MainAgentSection;
