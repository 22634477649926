import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import pools from "../../assets/img/pools-1.png"
import gym from "../../assets/img/weight-1.png"
import kids from "../../assets/img/playground-1.png"
import view from "../../assets/img/mountain-1.png"
import star from "../../assets/img/diamond-1.png"
import cleaning from "../../assets/img/cleaning-1.png"
import guide from "../../assets/img/tour-guide-1.png"
import Securite from "../../assets/img/secruity-1.png"


const PropertyTypes = () => {
    
    return (
        <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                <h1 className="mb-3 text-light">Amenities</h1>
                {/* <p className='text-light'>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p> */}
            </div>
            <div className="row g-4">
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black"}} >
                        <div className="rounded p-5 " style={{background:"#000000",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={pools} alt="Icon" style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>Pools</h6>
                            {/* <span>123 Properties</span> */}
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black",}}>
                        <div className="rounded p-5 " style={{background:"black",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={gym} alt="Icon"  style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>Gyms</h6>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black"}}>
                        <div className="rounded p-5 " style={{background:"black",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={kids} alt="Icon"  style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>Kids Play Area</h6>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black"}}>
                        <div className="rounded p-5 " style={{background:"black",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={view} alt="Icon"  style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>Beautiful Views</h6>
                        
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black"}}>
                        <div className="rounded p-5 " style={{background:"black",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={star} alt="Icon"  style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>Top Notch Service</h6>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black"}}>
                        <div className="rounded p-5 " style={{background:"black",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={cleaning} alt="Icon"  style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>Sterile</h6>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black"}}>
                        <div className="rounded p-5 " style={{background:"black",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={guide} alt="Icon"  style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>Assigned Tour Guide</h6>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                    <Link className="cat-item d-block  text-center rounded p-3" href="" style={{background:"black"}}>
                        <div className="rounded p-5 " style={{background:"black",border:"1px solid rgba(0, 185, 142, .3)"}}>
                            <div className="icon mb-3">
                                <img className="img-fluid" src={Securite} alt="Icon" style={{width:"60px",height:"60px"}} />
                            </div>
                            <h6 className='text-white'>24/7 Surveillance</h6>
                        </div>
                    </Link>
                </div>
            </div>
            </div>
            
            
    </div>

    );

};

export default PropertyTypes;
