import React from "react"
import { Link } from 'react-router-dom';

import headerImg from "../../assets/img/header.jpg"
import pic5 from "../../assets/img/pic5.jpg"

const GenericSection = ({title}) => {
   
    return (
        <>
              <div class="container-fluid header  p-0" style={{backgroundColor:"black"}}>
            <div class="row g-0 align-items-center flex-column-reverse flex-md-row">
                <div class="col-md-6 p-5 mt-lg-5">
                        <h1 class="display-5 animated text-light fadeIn mb-4">{ title}</h1> 
                        <nav aria-label="breadcrumb animated fadeIn">
                        <ol class="breadcrumb text-uppercase">
                            <li class="breadcrumb-item"><Link href="#">Home</Link></li>
                            <li class="breadcrumb-item"><Link href="#">Pages</Link></li>
                                <li class="breadcrumb-item  text-body active" aria-current="page">{ title}</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-md-6 animated fadeIn">
                    <img class="img-fluid" src={pic5} alt="" />
                </div>
            </div>
        </div>
    
       
        </>
    );

};

export default GenericSection;
