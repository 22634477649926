import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import property1 from "../../assets/img/IMG_3.JPG"
import property2 from "../../assets/img/IMG_4.JPG"
import property3 from "../../assets/img/p4.jpeg"
import property4 from "../../assets/img/IMG_6.JPG"
import property5 from "../../assets/img/IMG_7.JPG"
import property6 from "../../assets/img/p2.jpeg"


const PropertyListing = () => {
    
    return (
        <div className="container-xxl py-5" id="target-section">
        <div className="container">
            <div className="row g-0 gx-5 align-items-end">
                <div className="col-lg-12">
                    <div className="text-center mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                        <h1 className="mb-3 text-light">Our Property</h1>
                        { <p className='text-light'>Stunning apartment in Burj Royale, Downtown Dubai, offering breathtaking Burj Khalifa views, luxurious interiors, and world-class amenities including a rooftop pool, state-of-the-art gym, and concierge services—experience the pinnacle of city living. Address: 57RJ+J5H - Burj Khalifa Blvd - Downtown Dubai - Dubai - United Arab Emirates</p> }
                    </div>
                </div>
                {/* <div className="col-lg-6 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s">
                    <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
                        <li className="nav-item me-2">
                            <Link className="btn btn-outline-primary active text-light" data-bs-toggle="pill" href="#tab-1">Featured</Link>
                        </li>
                        <li className="nav-item me-2">
                            <Link className="btn btn-outline-primary text-light" data-bs-toggle="pill" href="#tab-2">For Sell</Link>
                        </li>
                        <li className="nav-item me-0">
                            <Link className="btn btn-outline-primary text-light" data-bs-toggle="pill" href="#tab-3">For Rent</Link>
                        </li>
                    </ul>
                </div> */}
            </div>
            <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="property-item rounded overflow-hidden" style={{border:"0.5px solid white"}}>
                                <div className="position-relative overflow-hidden">
                                    <Link to="tel:+971555661839" ><img className="img-fluid" src={property1} alt="" /></Link>
        
                                </div>
                               
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="property-item rounded overflow-hidden" style={{border:"0.5px solid white"}}>
                                <div className="position-relative overflow-hidden">
                                    <Link to="tel:+971555661839" ><img className="img-fluid" src={property2} alt="" /></Link>
        
                                </div>
                               
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="property-item rounded overflow-hidden" style={{border:"0.5px solid white"}}>
                                <div className="position-relative overflow-hidden">
                                    <Link to="tel:+971555661839" ><img className="img-fluid" src={property3} alt=""   /></Link>
        
                                </div>
                               
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="property-item rounded overflow-hidden" style={{border:"0.5px solid white"}}>
                                <div className="position-relative overflow-hidden">
                                    <Link to="tel:+971555661839" ><img className="img-fluid" src={property4} alt="" /></Link>
        
                                </div>
                               
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="property-item rounded overflow-hidden" style={{border:"0.5px solid white"}}>
                                <div className="position-relative overflow-hidden">
                                    <Link to="tel:+971555661839" ><img className="img-fluid" src={property5} alt="" /></Link>
        
                                </div>
                               
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="property-item rounded overflow-hidden" style={{border:"0.5px solid white"}}>
                                <div className="position-relative overflow-hidden">
                                    <Link to="tel:+971555661839" ><img className="img-fluid" src={property6} alt="" /></Link>
        
                                </div>
                               
                            </div>
                            </div>

                        
    
                            
                        
                        <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                            <Link className="btn btn-primary py-3 px-5" to="tel:+971555661839" >Book Now</Link>
                        </div>
                    </div>
                </div>
                {/* <div id="tab-2" className="tab-pane fade show p-0">
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sell</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Appartment</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Rent</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Villa</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sell</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Office</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Rent</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Building</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sell</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Home</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Rent</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Shop</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <Link className="btn btn-primary py-3 px-5" href="">Browse More Property</Link>
                        </div>
                    </div>
                </div>
                <div id="tab-3" className="tab-pane fade show p-0">
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sell</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Appartment</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Rent</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Villa</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sell</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Office</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Rent</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Building</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sell</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Home</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <Link href=""><img className="img-fluid" src={property} alt="" /></Link>
                                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Rent</div>
                                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Shop</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="text-primary mb-3">$12,345</h5>
                                    <Link className="d-block h5 mb-2" href="">Golden Urban House For Sell</Link>
                                    <p><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</p>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                    <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <Link className="btn btn-primary py-3 px-5" href="">Browse More Property</Link>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    );

};

export default PropertyListing;
