import React from "react";
import { Link } from 'react-router-dom';
import videoSource from "../../assets/img/update-video-1.mp4"; // Replace with your video path

const Slider1 = () => {
    const containerStyle = {
        position: "relative",
        height: "100vh", // Use full viewport height
        width: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center", // Center vertically
        justifyContent: "center", // Center horizontally
        marginTop: "0", // Remove any margin or padding that could push the video down
    };

    const videoStyle = {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        position: "absolute", // Ensure video is positioned behind the overlay
        top: "3rem",
        left: 0,
    };

    const overlayStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1, // Ensure the overlay is above the video
    };

    const contentStyle = {
        position: "relative",
        zIndex: 2, // Ensure the content is above the overlay
        color: "white",
        textAlign: "center", // Center text horizontally
    };

    return (
        <>
            <div className="container-fluid header p-0" style={containerStyle}>
                <video autoPlay loop muted playsInline style={videoStyle}>
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div style={overlayStyle}></div>
                <div className="row g-0 align-items-center" style={contentStyle}>
                    <div className="col-md-12 col-lg-12 p-5 text-container">
                        <h1 className="display-5 animated text-light fadeIn mb-4">Premier <span className="text-light">Living with </span> Iconic Views</h1>
                        <p className="animated fadeIn mb-4 pb-2">Indulge in the Dubai downtown living</p>
                        <Link to="tel:+971555661839" className="btn btn-primary py-3 px-4 me-2"><i className="fa fa-phone-alt me-2"></i>Contact Us</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Slider1;
