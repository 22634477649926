import React from 'react';
import Slider2 from '../../components/sliders/slider2';
const ClientsSection = () => {
    const options1 = {
        items: 4,
        nav: true,
        rewind: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplaySpeed: 2000,
        margin: 10, // Gap between items
        responsive: {
            0: {
                items: 1 // 1 item for small screens
            },
            600: {
                items: 1 // 2 items for screens larger than 600px
            },
            1000: {
                items: 3 // 4 items for screens larger than 1000px
            }
        }
    };
        
    return (
        <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                <h1 className="mb-3 text-light">Our Clients Say!</h1>
                <p className='text-light'>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
            </div>
                <Slider2 options1={options1} />
        </div>
    </div>
    );
};

export default ClientsSection;
