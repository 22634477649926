import React from 'react';
import { Link } from 'react-router-dom';

const ScrolButton = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    return (
        <>
         
         <Link onClick={scrollToTop} href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></Link>
        </>
    );
};

export default ScrolButton;
