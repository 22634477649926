import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import profile from "../../assets/img/profile.jpeg"
const OwnerSection = () => {
    return (
        <div className="container-xxl py-5" id="#meet-the-ceo">
        <div className="container">
            <div className="row g-5 align-items-center">
          
                    <div className="col-lg-6 wow fadeIn" style={{marginTop:"-2rem"}} data-wow-delay="0.5s">
                    <h1 className="mb-4 text-light">Meet the CEO</h1>
                
                    <p className="mb-4 text-light">As a young entrepreneur with a passion for business, I bring over eight years of diverse experience in fields such as Sales, Event Management, Aviation Pricing, Accounting, Digital Marketing, Real Estate, and Development Management. My journey has taken me across India and Dubai, enriching my perspective and skills in these dynamic markets.

Real estate has been an integral part of my journey, providing me with a deep understanding of the importance of location and the nuances of catering to clients' needs. I am excited to now venture into the holiday homes space, where I aim to offer exceptional experiences and unparalleled service to our guests.</p>
                    {/* <p className='text-light'><i className="fa fa-check text-primary me-3"></i>Tempor erat elitr rebum at clita</p>
                    <p className='text-light'><i className="fa fa-check text-primary me-3"></i>Aliqu diam amet diam et eos</p>
                    <p className='text-light'><i className="fa fa-check text-primary me-3"></i>Clita duo justo magna dolore erat amet</p>
                     */}
                    {/* <Link  to=":"className="btn btn-primary py-3 px-5 mt-3" href="">Know More</Link> */}
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="about-img position-relative overflow-hidden p-5 pe-0">
                        <img className="img-fluid w-100" src={profile}  alt="cons"/>
                    </div>
                </div>
                    
                
                </div>
                
                
        </div>
    </div>
    );
};

export default OwnerSection;
